var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.userData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(_vm._s(_vm.$t("An error occurred while receiving data.")))]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t("Client not found with this id."))+" "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'apps-users-list' }}},[_vm._v(" "+_vm._s(_vm.$t("Check the")))]),_vm._v(_vm._s(_vm.$t("client list."))+" ")],1)]),(_vm.userData && _vm.$can('read', ((_vm.userData.isFirmSubject ? 'F' : 'S') + ".Subject")))?[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12","xl":"9","lg":"8","md":"7"}},[_c('user-view-user-info-card',{attrs:{"user-data":_vm.userData}})],1),_c('b-col',{attrs:{"cols":"12","md":"5","xl":"3","lg":"4"}},[_c('test-card',{attrs:{"test-data":_vm.testData,"program-data":_vm.userData.program,"grade":_vm.userData.subjectClass,"isFirm":_vm.userData.isFirmSubject,"isActive":_vm.userData.active,"subjectID":_vm.userData._id}})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"4","md":"4","sm":"4"}},[_c('statistic-card-vertical',{attrs:{"color":"info","icon":"FilePlusIcon","statistic":Math.round(_vm.userData.program.exercisesDone / 12),"statistic-title":_vm.$t('Sets Done')}})],1),_c('b-col',{attrs:{"xl":"4","md":"4","sm":"4"}},[_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"FileIcon","statistic":Math.round(
              (_vm.userData.program.exercisesTotal - _vm.userData.program.exercisesDone) / 12
            ),"statistic-title":_vm.$t('Unsolved Sets')}})],1),_c('b-col',{attrs:{"xl":"4","md":"4","sm":"4"}},[_c('b-link',{attrs:{"to":_vm.userData.program.exercisesPrinted - _vm.userData.program.exercisesDone == 0
              ? {}
              : {
                  name: 'exercises-detail',
                  params: {
                    userId: _vm.userData.id,
                  },
                }}},[_c('statistic-card-vertical',{attrs:{"color":_vm.userData.program.exercisesPrinted - _vm.userData.program.exercisesDone == 0
                ? 'success'
                : 'danger',"icon":"FileTextIcon","statistic":_vm.userData.program.exercisesPrinted - _vm.userData.program.exercisesDone,"statistic-title":_vm.$t('Exercises Waiting Results')}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[0],"description":_vm.$t('Attention').toUpperCase(),"statistic-title":_vm.$t('Group Change'),"chart-data":[
            {
              name: _vm.$t('Attention'),
              data: _vm.userData.program.groups.attention.change.map(function (x) { return 6 - x; }).concat( [6 - _vm.userData.program.groups.attention.group] ),
            } ],"color":_vm.chartColors[0],"chart-options":_vm.groupChartOptions}})],1),_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[1],"description":_vm.$t('Logic').toUpperCase(),"statistic-title":_vm.$t('Group Change'),"chart-data":[
            {
              name: _vm.$t('Logic'),
              data: _vm.userData.program.groups.logic.change.map(function (x) { return 6 - x; }).concat( [6 - _vm.userData.program.groups.logic.group] ),
            } ],"color":_vm.chartColors[1],"chart-options":_vm.groupChartOptions}})],1),_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[2],"description":_vm.$t('Concentration').toUpperCase(),"statistic-title":_vm.$t('Group Change'),"chart-data":[
            {
              name: _vm.$t('Concentration'),
              data: _vm.userData.program.groups.concentration.change.map(function (x) { return 6 - x; }).concat( [6 - _vm.userData.program.groups.concentration.group] ),
            } ],"color":_vm.chartColors[2],"chart-options":_vm.groupChartOptions}})],1),_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[3],"description":_vm.$t('Memory').toUpperCase(),"statistic-title":_vm.$t('Group Change'),"chart-data":[
            {
              name: _vm.$t('Memory'),
              data: _vm.userData.program.groups.memory.change.map(function (x) { return 6 - x; }).concat( [6 - _vm.userData.program.groups.memory.group] ),
            } ],"color":_vm.chartColors[3],"chart-options":_vm.groupChartOptions}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":_vm.$t('Performance Graph')}},[_c('b-card-header',[_c('b-row',{staticStyle:{"display":"flex","justify-content":"flex-end","width":"100%"}},[_c('b-button',{staticClass:"mr-5",staticStyle:{"float":"right"},attrs:{"to":{
                  name: 'subject-report',
                  params: {
                    userId: _vm.userData.id,
                  },
                },"target":"_blank","variant":"primary"}},[_vm._v(_vm._s(_vm.$t("Subject Report")))]),_c('b-button',{staticStyle:{"float":"right"},attrs:{"variant":"info","to":{
                  name: 'compare-report',
                  params: {
                    userId: _vm.userData.id,
                  },
                }}},[_vm._v(_vm._s(_vm.$t("Comparison Report")))])],1)],1),_c('vue-apex-charts',{key:_vm.refreshKey,attrs:{"height":"500","width":"100%","options":_vm.lineChartModifiedOptions,"series":_vm.bigChartData}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[0],"statistic-title":_vm.$t('Time-Mistake Averages'),"description":_vm.$t('Attention').toUpperCase(),"chart-data":_vm.smallChartData[0].series,"color":_vm.chartColors[0],"chart-options":_vm.areaChartModifiedOptions}})],1),_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[1],"statistic-title":_vm.$t('Time-Mistake Averages'),"description":_vm.$t('Logic').toUpperCase(),"chart-data":_vm.smallChartData[1].series,"color":_vm.chartColors[1],"chart-options":_vm.areaChartModifiedOptions}})],1),_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[2],"statistic-title":_vm.$t('Time-Mistake Averages'),"description":_vm.$t('Concentration').toUpperCase(),"chart-data":_vm.smallChartData[2].series,"color":_vm.chartColors[2],"chart-options":_vm.areaChartModifiedOptions}})],1),_c('b-col',{attrs:{"xl":"3","md":"6","sm":"6"}},[_c('statistic-card-with-area-chart',{key:_vm.refreshKey,attrs:{"icon":_vm.panelIcons[3],"statistic-title":_vm.$t('Time-Mistake Averages'),"description":_vm.$t('Memory').toUpperCase(),"chart-data":_vm.smallChartData[3].series,"color":_vm.chartColors[3],"chart-options":_vm.areaChartModifiedOptions}})],1)],1)]:[_c('div',[_c('p',[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}}),_vm._v(" "+_vm._s(_vm.$t("You are not allowed to see this client😕"))+" ")],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }