<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">{{ $t("An error occurred while receiving data.") }}</h4>
      <div class="alert-body">
        {{ $t("Client not found with this id.") }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t("Check the") }}</b-link
        >{{ $t("client list.") }}
      </div>
    </b-alert>
    <template
      v-if="userData && $can('read', `${userData.isFirmSubject ? 'F' : 'S'}.Subject`)"
      id="analytic-stat-field"
    >
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <test-card
            :test-data="testData"
            :program-data="userData.program"
            :grade="userData.subjectClass"
            :isFirm="userData.isFirmSubject"
            :isActive="userData.active"
            :subjectID="userData._id"
          />
        </b-col>
      </b-row>
      <!-- Second Row -->
      <b-row class="match-height">
        <b-col xl="4" md="4" sm="4">
          <statistic-card-vertical
            color="info"
            icon="FilePlusIcon"
            :statistic="Math.round(userData.program.exercisesDone / 12)"
            :statistic-title="$t('Sets Done')"
          />
        </b-col>
        <b-col xl="4" md="4" sm="4">
          <statistic-card-vertical
            color="warning"
            icon="FileIcon"
            :statistic="
              Math.round(
                (userData.program.exercisesTotal - userData.program.exercisesDone) / 12
              )
            "
            :statistic-title="$t('Unsolved Sets')"
          />
        </b-col>
        <b-col xl="4" md="4" sm="4">
          <b-link
            :to="
              userData.program.exercisesPrinted - userData.program.exercisesDone == 0
                ? {}
                : {
                    name: 'exercises-detail',
                    params: {
                      userId: userData.id,
                    },
                  }
            "
          >
            <statistic-card-vertical
              :color="
                userData.program.exercisesPrinted - userData.program.exercisesDone == 0
                  ? 'success'
                  : 'danger'
              "
              icon="FileTextIcon"
              :statistic="
                userData.program.exercisesPrinted - userData.program.exercisesDone
              "
              :statistic-title="$t('Exercises Waiting Results')"
            />
          </b-link>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[0]"
            :description="$t('Attention').toUpperCase()"
            :statistic-title="$t('Group Change')"
            :chart-data="[
              {
                name: $t('Attention'),
                data: [
                  ...userData.program.groups.attention.change.map((x) => 6 - x),
                  6 - userData.program.groups.attention.group,
                ],
              },
            ]"
            :color="chartColors[0]"
            :key="refreshKey"
            :chart-options="groupChartOptions"
          />
        </b-col>
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[1]"
            :description="$t('Logic').toUpperCase()"
            :statistic-title="$t('Group Change')"
            :chart-data="[
              {
                name: $t('Logic'),
                data: [
                  ...userData.program.groups.logic.change.map((x) => 6 - x),
                  6 - userData.program.groups.logic.group,
                ],
              },
            ]"
            :color="chartColors[1]"
            :key="refreshKey"
            :chart-options="groupChartOptions"
          />
        </b-col>
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[2]"
            :description="$t('Concentration').toUpperCase()"
            :statistic-title="$t('Group Change')"
            :chart-data="[
              {
                name: $t('Concentration'),
                data: [
                  ...userData.program.groups.concentration.change.map((x) => 6 - x),
                  6 - userData.program.groups.concentration.group,
                ],
              },
            ]"
            :color="chartColors[2]"
            :key="refreshKey"
            :chart-options="groupChartOptions"
          />
        </b-col>
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[3]"
            :description="$t('Memory').toUpperCase()"
            :statistic-title="$t('Group Change')"
            :chart-data="[
              {
                name: $t('Memory'),
                data: [
                  ...userData.program.groups.memory.change.map((x) => 6 - x),
                  6 - userData.program.groups.memory.group,
                ],
              },
            ]"
            :color="chartColors[3]"
            :key="refreshKey"
            :chart-options="groupChartOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card :title="$t('Performance Graph')">
            <b-card-header>
              <b-row style="display: flex; justify-content: flex-end; width: 100%">
                <b-button
                  :to="{
                    name: 'subject-report',
                    params: {
                      userId: userData.id,
                    },
                  }"
                  target="_blank"
                  style="float: right"
                  variant="primary"
                  class="mr-5"
                  >{{ $t("Subject Report") }}</b-button
                >
                <b-button
                  style="float: right"
                  variant="info"
                  :to="{
                    name: 'compare-report',
                    params: {
                      userId: userData.id,
                    },
                  }"
                  >{{ $t("Comparison Report") }}</b-button
                >
              </b-row>
            </b-card-header>
            <vue-apex-charts
              height="500"
              width="100%"
              :options="lineChartModifiedOptions"
              :series="bigChartData"
              :key="refreshKey"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[0]"
            :statistic-title="$t('Time-Mistake Averages')"
            :description="$t('Attention').toUpperCase()"
            :chart-data="smallChartData[0].series"
            :color="chartColors[0]"
            :key="refreshKey"
            :chart-options="areaChartModifiedOptions"
          />
        </b-col>
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[1]"
            :statistic-title="$t('Time-Mistake Averages')"
            :description="$t('Logic').toUpperCase()"
            :chart-data="smallChartData[1].series"
            :color="chartColors[1]"
            :key="refreshKey"
            :chart-options="areaChartModifiedOptions"
          />
        </b-col>
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[2]"
            :statistic-title="$t('Time-Mistake Averages')"
            :description="$t('Concentration').toUpperCase()"
            :chart-data="smallChartData[2].series"
            :color="chartColors[2]"
            :key="refreshKey"
            :chart-options="areaChartModifiedOptions"
          />
        </b-col>
        <b-col xl="3" md="6" sm="6">
          <statistic-card-with-area-chart
            :icon="panelIcons[3]"
            :statistic-title="$t('Time-Mistake Averages')"
            :description="$t('Memory').toUpperCase()"
            :chart-data="smallChartData[3].series"
            :color="chartColors[3]"
            :key="refreshKey"
            :chart-options="areaChartModifiedOptions"
          />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <div>
        <p>
          <b-spinner type="grow" variant="primary" />
          {{ $t("You are not allowed to see this client😕") }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
  BCardHeader,
  BSpinner,
  BButton,
} from "bootstrap-vue";
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import UserViewUserInfoCard from "@/views/apps/user/users-view/UserViewUserInfoCard.vue";
import ChartsjsLineChart from "@/views/apps/user/users-view/ChartjsLineChart.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import VueApexCharts from "vue-apexcharts";
import userStoreModule from "../userStoreModule";
// import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import TestCard from "./TestCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
import { areaChartOptions } from "@core/components/statistics-cards/chartOptions";
import { areaChartOptionsModify } from "./areaChartOptionsModify";
import { lineChartOptions } from "./lineChartOptions";
import { groupChartOptionsModify } from "./groupChartOptions";
import axios from "@axios";
import StatisticCardWithLineChart from "@/@core/components/statistics-cards/StatisticCardWithLineChart.vue";
// import userData from "@/store/user-data";
// import {t as translate} from "@/libs/i18n";
const {
  axiosRoutes: { exercise: path },
} = require("/G_CONFIG");

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardHeader,
    BSpinner,
    BButton,
    // Local Components
    UserViewUserInfoCard,
    TestCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    ChartsjsLineChart,
    StatisticCardVertical,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    VueApexCharts,
    StatisticCardWithLineChart,
  },
  data() {
    return {
      refreshKey: 0,
      lineChartModifiedOptions: {
        ...lineChartOptions,
      },

      bigChartData: [
        {
          name: "",
          data: [],
          series: [],
        },
        { name: "", data: [], series: [] },
        { name: "", data: [], series: [] },
        {
          name: "",
          data: [],
          series: [],
        },
      ],
      areaChartModifiedOptions: {
        ...areaChartOptions,
        ...areaChartOptionsModify,
      },

      panelTitles: ["", "", "", ""],
      panelIcons: ["AlertCircleIcon", "PieChartIcon", "CrosshairIcon", "DiscIcon"],
      chartColors: ["primary", "success", "info", "warning"],
      smallChartData: [
        {
          series: [
            { name: this.$t("Attention"), data: [0] },
            { name: this.$t("Attention"), data: [0] },
          ],
        },
        {
          series: [
            { name: this.$t("Concentration"), data: [0] },
            { name: this.$t("Concentration"), data: [0] },
          ],
        },
        {
          series: [
            { name: this.$t("Logic"), data: [0] },
            { name: this.$t("Logic"), data: [0] },
          ],
        },
        {
          series: [
            { name: this.$t("Memory"), data: [0] },
            { name: this.$t("Memory"), data: [0] },
          ],
        },
      ],
      groupChartOptions: {
        ...areaChartOptions,
        ...areaChartOptionsModify,
        ...groupChartOptionsModify,
      },
    };
  },
  mounted() {
    // console.log(locale);
  },
  methods: {
    dataFetched(data) {
      const names = (this.panelTitles = Object.keys(data.smallChartData));
      for (let i = 0; i < names.length; i++) {
        this.smallChartData[i].series[0].data = data.smallChartData[names[i]][1].data;
        this.smallChartData[i].series[0].name = data.smallChartData[names[i]][1].label;
        this.smallChartData[i].series[1].data = data.smallChartData[names[i]][0].data;
        this.smallChartData[i].series[1].name = data.smallChartData[names[i]][0].label;
        this.panelTitles[i] = this.$t(names[i]);
      }
      this.refreshKey++;
    },
  },
  async created() {
    document.getElementById("loading-bg").style.display = "block";
    const id = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    try {
      const { data: response } = await axios.get(`${path.stats + id}`);
      this.bigChartData = response.bigChartData;
      this.dataFetched(response);
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("loading-bg").style.display = "none";
    }
  },
  setup() {
    const userData = ref(null);
    const testData = ref(null);
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.subject;
        testData.value = response.data.testData;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });
    return {
      userData,
      testData,
    };
  },
};
</script>

<style scoped>
.client-rows {
  margin-top: -20px;
}
</style>
